<template>
  <section class="aboutIdentity">
    <div class="aboutIdentity-container">
      <div class="aboutIdentity-item" v-for="(item, key, idx) in $locale['object']['about-identity']" :key="idx">
        <h3 class="aboutIdentity-title special-title">{{ item.title }}</h3>
        <p class="aboutIdentity-description">{{ item.desc }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss">
.aboutIdentity {
  color: $shark;
  font-family: $sec_font;
  &-container {
    display: flex;
    flex-direction: column;
    gap: 58px;
    padding: 58px 7%;
  }
  &-description {
    font-family: $font_text;
    font-size: 17px;
  }
  @media (min-width: $tablet_width) {
    &-container {
      flex-direction: row;
      gap: 20px;
      padding: 165px 7% 50px;
      justify-content: center;
    }
    &-item {
      max-width: 274px;
    }
  }
  @media (min-width: $desktop_width) {
    &-container {
      gap: 74px;
      padding: 206px 0 124px;
      justify-content: center;
    }
    &-item {
      max-width: 407px;
    }
    &-title {
      font-size: 24px;
    }
    &-description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
