<template>
  <section class="aboutInfoBanner">
    <div class="aboutInfoBanner-container main">
      <div class="aboutInfoBanner-image" id="imageTeam" ref="imageTeam">
        <img :src="banner.image" :alt="banner.title" />
      </div>
      <div class="aboutInfoBanner-info">
        <h2 class="aboutInfoBanner-title special-title">{{ banner.title }}</h2>
        <p class="aboutInfoBanner-desc">{{ banner.desc }}</p>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    banner() {
      return this.$locale["object"]["about-info-banner"];
    },
  },
  methods: {
    loadTeam(entri) {
      const { target, isIntersecting, intersectionRatio } = entri[0];
      const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
      isVisible ? target.classList.add("active") : target.classList.remove("active");
    },
  },
  mounted() {
    const observer = new IntersectionObserver(this.loadTeam, { threshold: 0.4 });
    observer.observe(this.$refs.imageTeam);
  },
};
</script>
<style lang="scss">
.aboutInfoBanner {
  padding: 62px 20px;
  font-family: $sec_font;
  &-image {
    max-width: 438px;
    margin: 0 auto 40px;
    transform: scale(0.2);
    opacity: 0;
    transition: 2s ease-in-out all;
    &.active {
      opacity: 1;
      transform: scale(1) translateX(0);
    }
    img {
      border-radius: 12px;
      width: 100%;
    }
  }
  &-info {
    max-width: 438px;
    margin: 0 auto;
  }
  &-desc {
    font-family: $font_text;
    font-size: 17px;
    line-height: 17px;
  }
  @media (min-width: $tablet_width) {
    padding: 62px 28px 0px;

    &-container {
      display: grid;
      grid-template-columns: auto auto;
      gap: 21px;
    }
    &-image {
      margin-top: -40%;
      img {
        height: 641px;
        width: 438px;
        object-fit: cover;
        object-position: -16px;
      }
    }
    &-info {
      height: auto;
      margin-top: auto;
      margin-bottom: 104px;
    }
  }
  @media (min-width: $desktop_width) {
    padding: 62px 48px 60px;

    &-container {
      grid-template-columns: 70% 30%;
      gap: 28px;
    }
    &-image {
      max-width: 863px;
      margin-top: -155px;
      img {
        height: 641px;
        width: 100%;
        object-fit: cover;
        object-position: -16px;
      }
    }
    &-desc {
      font-size: 16px;
    }
    &-title {
      font-size: 24px;
    }
    &-info {
      max-width: 295px;
      margin-bottom: 140px;
    }
  }
}
</style>
