<template>
  <main class="about-container" v-if="this.$locale['object']['about-main-banner']">
    <GlobalMainBanner :bannerData="$locale['object']['about-main-banner']" />
    <AboutWeAre />
    <AboutIdentity />
    <!-- <AboutSlider /> -->
    <ServicesValues />
    <!-- <AboutInfoBanner /> -->
  </main>
  <Spinner v-else />
</template>

<script>
import AboutWeAre from "./AboutWeAre.vue";
import AboutIdentity from "./AboutIdentity.vue";
import AboutSlider from "./AboutSlider.vue";
import AboutInfoBanner from "./AboutInfoBanner.vue";
import GlobalMainBanner from "../global/GlobalMainBanner.vue";
import ServicesValues from "@/components/services/ServicesValues.vue";

export default {
  components: { AboutWeAre, AboutIdentity, AboutSlider, AboutInfoBanner, GlobalMainBanner, ServicesValues },
  async beforeMount() {
    if (!this.$locale["object"]["about-main-banner"]) await this.$store.dispatch("updateLocal");
  },
};
</script>

<style lang="scss"></style>
